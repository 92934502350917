<template>
    <PriceNegotiationForm />
</template>

<script>
import PriceNegotiationForm from '@/components/PriceNegotiation/PriceNegotiationForm'

export default {
    name: 'CreatePriceNegotiation',
    components: {
        PriceNegotiationForm
    },
    data: () => ({
        breadcrumbs: [
            { title: 'Главная', link: '/' },
            { title: 'Перечень согласования цен', link: '/price-negotiations' },
            { title: 'Создание запроса на согласование цен' }
        ]
    })
}
</script>
